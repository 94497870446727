'use strict';

// Import Libraries
import React, { useState } from 'react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { href, link_classes } from './utils';
import { builderDispatch, WebUI } from '../_utils/ui';

const MobileSideMenu = ({ headerLinks }) => {
  React.useEffect(() => {
    const builderDispatchLogin = builderDispatch.register(event => {
      switch (event.type) {
        case WebUI.LOGIN:
          updateComponent();
          break;
      }
    });
    return () => {
      builderDispatch.unregister(builderDispatchLogin);
    };
  });

  const [values, setValues] = useState({
    opened: false,
    display: 'none',
    update: false,
  });

  const updateComponent = () => {
    setValues({ ...values, update: !values.update });
  };

  const renderAccountTools = () => {
    const loggedIn = typeof window.App !== 'undefined' && window.App.order.logged_in();
    const { store_finder: storeFinderUrl, help_page: helpPageUrl } = headerLinks;

    const utilityLinks = () => {
      if (!storeFinderUrl && !helpPageUrl) {
        return null;
      }

      return (
        <>
          <li className="sfinder">
            <a href={storeFinderUrl} role="button">
              <i className="skinny-icon pin"></i>Store Finder
            </a>
          </li>
          <li className="help">
            <a href={helpPageUrl} role="button">
              <i className="skinny-icon help"></i>Help
            </a>
          </li>
        </>
      )
    }

    const renderHeaderLinks = () => {
      if (loggedIn) {
        const {
          logged_in_links: {
            order_prints: orderPrintsUrl,
            user_albums: userAlbumsUrl,
          },
        } = headerLinks;

        const uploadLinks = () => {
          const storageProviderPresent =
            typeof window.App !== 'undefined' &&
            window.App.order.storage_provider_present;
          if (!storageProviderPresent) {
            return null;
          }

          return (
            <>
              <li className="upload">
                <a href={orderPrintsUrl} role="button">
                  <i className="skinny-icon upload"></i>Upload Photos
                </a>
              </li>
              <li className="photos">
                <a href={userAlbumsUrl} role="button">
                  <i className="skinny-icon photos"></i>My Photos
                </a>
              </li>
            </>
          );
        };
        const userName =
          typeof window.App !== 'undefined' &&
          window.App.order.logged_in_user_name;

        const {
          logged_in_links: {
            user_projects: userProjectsUrl,
            user_account: userAccountUrl,
            track_orders: trackOrdersUrl,
            logout: logoutUrl,
          },
        } = headerLinks;

        return (
          <>
            <span className="logged-in">Hello, {userName}</span>
            <ul>
              {uploadLinks()}
              <li className="projects">
                <a href={userProjectsUrl} role="button">
                  <i className="skinny-icon projects"></i>My Projects
                </a>
              </li>
              <li className="account">
                <a href={userAccountUrl} role="button">
                  <i className="skinny-icon account"></i>My Account
                </a>
              </li>
              <li className="orders">
                <a href={trackOrdersUrl} role="button">
                  <i className="skinny-icon orders"></i>Track Orders
                </a>
              </li>
              {utilityLinks()}
              <li className="sign-out">
                <a href={logoutUrl} role="button">
                  <i className="skinny-icon sign-out"></i>Sign Out
                </a>
              </li>
            </ul>
          </>
        );
      } else {
        const {
          new_account: newAccountUrl,
          store_name: storeName,
        } = headerLinks;

        return (
          <>
            <span>Account</span>
            <ul>
              <li className="sign-in">
                <a href="#" data-login="" role="button">
                  <i className="skinny-icon anna sign-in"></i>Sign In
                </a>
              </li>
              <li className="create-account">
                <a href={newAccountUrl} role="button">
                  <i className="skinny-icon create-account"></i>Create Account
                  <small className="d-block">Get more out of {storeName}</small>
                </a>
              </li>
              {utilityLinks()}
            </ul>
          </>
        );
      }
    };

    return (
      <li id="login-status-burger" className="category">
        {renderHeaderLinks()}
      </li>
    );
  };

  return (
    <>
      <FocusTrap active={values.opened} focusTrapOptions={{ returnFocusOnDeactivate: true }}>
        <div>
          <button
            className="close"
            id="close-menu-button"
            data-target="#close-sidrs"
            type="button"
            aria-label="Close">
            <i className="skinny-icon close" />
          </button>
          <ul>
            {renderAccountTools()}
          </ul>
        </div>
      </FocusTrap>
    </>
  );
};
export default MobileSideMenu;
